import { api } from ":mods";
import { ENDPOINTS } from "../const";



export function getAvalaibleTutorDates() {
      return api
        .get(ENDPOINTS.GET_AVALIBLE_TUTOR_DATES)
        .then(async (res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {});
    }

    export async function getTutorTopics() {
      return api
        .get(ENDPOINTS.GET_TUTOR_TOPIC)
        .then(async (res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {});
    }
    